import React from 'react'
import PropTypes from 'prop-types'
import { Link } from "gatsby"
import Terms from '../pages/terms'
import Disclaimer from '../pages/disclaimer'


const Footer = (props) => (
    <footer id="footer" style={props.timeout ? {display: 'none'} : {}}>
         <ul className="icons">
            <li><a href="https://www.facebook.com/fudoantea" className="icon fa-facebook"><span className="label">Facebook</span></a></li>
            <li><a href="https://www.instagram.com/fudoantea" className="icon fa-instagram"><span className="label">Instagram</span></a></li>
            <li><a href="https://www.linkedin.com/company/fudoan" className="icon fa-linkedin"><span className="label">GitHub</span></a></li>
            <a style={{opacity: '.7'}}target="_blank" href="https://www.theknot.com/marketplace/redirect-2025798?utm_source=vendor_website&utm_medium=banner&utm_term=f96c9c65-47e9-451b-90d5-475fd9696012&utm_campaign=vendor_badge_assets"><img alt="TheKnot.com" border="0" height="75" src="https://www.xoedge.com/myaccount/2019/grab-a-badge/social/flat/large/round.png" width="35" height="35"></img></a>     
          </ul>
  <p className="copyright">&copy; 2019 Fudō-An Tea & Wellness Services, LLC | <Link to="/terms">Terms & Conditions</Link> | <Link to="/disclaimer">Disclaimer</Link></p>
    </footer>
)

Footer.propTypes = {
    timeout: PropTypes.bool
}

export default Footer
