import React from 'react'
import PropTypes from 'prop-types'
import whitelogo from '../images/white-fudo-logo.svg'



const Header = (props) => (
    <header id="header" style={props.timeout ? {display: 'none'} : {}}>
        <div className="logo">
        <span><img width="90%" src={whitelogo} alt="" /></span>
        </div>
        <div className="content">
            <div className="inner">
                <h1>EXPERIENCE JAPANESE TEA CEREMONY</h1>
                <h5>We bring the ancient art of preparing matcha to your next event</h5>
            
            </div>
                <ul className="actions">
              <li><a href="javascript:;" onClick={() => {props.onOpenArticle('booknow')}}><input type="submit" value="Book Now" className="special" /></a></li>
            </ul>
            </div>
       
        <nav>
            <ul>
                <li><a href="javascript:;" onClick={() => {props.onOpenArticle('services')}}>Services</a></li>
                <li><a href="javascript:;" onClick={() => {props.onOpenArticle('about')}}>About</a></li>
                <li><a href="javascript:;" onClick={() => {props.onOpenArticle('contact')}}>Contact</a></li>
            </ul>
        </nav>
    </header>
)

Header.propTypes = {
    onOpenArticle: PropTypes.func,
    timeout: PropTypes.bool
}

export default Header 

