import React from 'react'
import PropTypes from 'prop-types'
import HubspotForm from 'react-hubspot-form'
import img1 from '../images/img1.jpg'
import img2 from '../images/img2.jpg'
import img3 from '../images/img3.jpg'
import img8 from '../images/img8.jpg'
import img5 from '../images/img5.jpg'
import img4 from '../images/img4.jpg'
import img6 from '../images/img6.jpg'
import img9 from '../images/img9.png'
import img10 from '../images/img10.png'
import img11 from '../images/img11.jpg'
import img12 from '../images/img12.jpg'
import services from '../images/services-icons.png'
import natsume from '../images/hero-4.png'
import aislynnabout from '../images/aislynn1.png'
import 'bootstrap/dist/css/bootstrap.min.css'
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

  
class Main extends React.Component {
 
      render() {
        var settings = {
          dots: false,
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
          adaptiveHeight: true,
          swipeToSlide: true
        }
      
    let close = <div className="close" onClick={() => {this.props.onCloseArticle()}}></div>

    return (
      <div ref={this.props.setWrapperRef} id="main" style={this.props.timeout ? {display: 'flex'} : {display: 'none'}}>

        <article id="booknow" className={`${this.props.article === 'booknow' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">Book Your Ceremony</h2>
        	<div>

          <HubspotForm
          portalId="7874372"
          formId= "f9c0179e-0662-4cba-828b-5633de8e3e8c"
   onSubmit={() => console.log('Submit!')}
   onReady={(form) => console.log('Form ready!')}
   loading={<div>Loading...</div>}
   />
        </div>

          {close}
        </article>
     

        <article id="services" className={`${this.props.article === 'services' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">Services</h2>
        <p>Our goal is to help clients connect the spiritual, mindful, and physical beauty that the tea room creates into actions that bring peace to their everyday lives. We expect all of our guests to leave feeling more calm, centered, and rejuvinated than they entered.<br />Providing a tea ceremony for your office or event can:
        <span className="image main"><img style={{width: '70%', margin: 'auto'}} src={services} alt="" /></span>
           
           Each ceremony is unique - they can be tailored specifically to your needs. We work on an individual basis with you to determine duration, pricing, and formality based on your event. 
         You can choose a wellness workshop with a different emphasis of your choice: meditation and mindfulness, matcha education, zen aesthetics, or public demonstration. All include an introduction to the history and philosophy of tea ceremony, tea room setup, cleanup, and a bowl of matcha and traditional Japanese sweet for guests. All you need to bring is yourself!</p>
          <br /><h5 style={{textAlign: "center"}}>Contact us to plan your ceremony today. </h5>
          <ul className="actions">
              <li style={{display: 'block', margin: 'auto'}}><a href="https://share.hsforms.com/1-cAXngZiTLqCi1Yz3o4-jA4orwk"><input style={{margin: 'auto', display: 'block'}} type="submit" value="Inquire" className="contact" /></a></li>
            </ul>
      <div style={{width: '80%', margin: 'auto'}}>
          <Slider {...settings}>
          <div>
            <span className="image main"><img style={{width: '50%', margin:'auto'}}src={img10} alt="" /></span>
            </div>
            <div>
            <span className="image main"><img src={img1} alt="" /></span>
            </div>
            <div>
            <span className="image main"><img style={{width: '50%', margin:'auto'}}src={img4} alt="" /></span>
            </div>
            <div>
            <span className="image main"><img style={{width: '70%', margin: 'auto'}}className="flip-image" src={img12} alt="" /></span>
            </div>
            <div>
            <span className="image main"><img src={img2} alt="" /></span>
            </div>
            <div>
            <span className="image main"><img className="rotate-image" style={{width: "100%"}}src={img3} alt="" /></span>
            </div>
            <div>
            <span className="image main"><img src={img8} alt="" /></span>
            </div>
            <div>
            <span className="image main"><img src={img5} alt="" /></span>
            </div>
            <div>
            <span className="image main"><img src={img6} alt="" /></span>
            </div>
            <div>
            <span className="image main"><img style={{width: '70%', margin: 'auto'}}src={img11} alt="" /></span>
            </div>
          </Slider>
        </div>
         {close}
        </article>

        <article id="about" className={`${this.props.article === 'about' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none',flex: 1, flexDirection: 'row', flexWrap: 'wrap', alignItems: 'flex-start'}}>
          
      <h2 className="major">About</h2>
      <h4>What is Japanese Tea Ceremony?</h4>
      <span style={{width: '100%', margin: 'auto'}}className="image main"><img src={img9} alt="" /></span>
      
      <br />
      <p>Originating in 16th century Japan, the method of preparing matcha, or powdered green tea, was formalized by Sen No Rikyu. Rikyu emphasized the concept of 'wabi-sabi', or rustic beauty - that all things are unfinished, imperfect, temporary. 
      Today, we still study his principles of harmony, respect, purity, and tranquility. Most importantly, each ceremony will never occur again, and must be appreciated in the moment.</p>

<div id="row-2col">
<p>The tea room was used for diplomatic, political and business relations. For this reason, it was one of the only places samurai would remove their swords. Tea rooms are a space where time, status, and qualms outside their walls do not exist.
<br /><br />
<span><i style={{textAlign: 'center'}}>It is a ceremony of unity- between host and guests; mind and body; imperfection and beauty. </i></span>
<br /><br />
Fudō-An brings this beautiful dispaly to all. We work with you to create an experience that’s unique, cultural, and unforgettable.</p>
<span style={{width: '50%', margin: 'auto'}} className="image main"><img src={natsume} alt="" /></span>
          </div>
          <br />
          <h4> What is Fudō-An? </h4>
          <p>Fudō-An translates from Japanese to 'floating tea house'. The founder chose this name because our business is a transportable platform - we bring the tea house to you! Normally, one has to travel to a traditional Japanese tea room to experience a ceremony, but we allow people to experience this special, exclusive ceremony in the comfort of your own home, office, or event venue. </p>
    <div id="row-2col">
        <span style={{width: '100%', margin: 'auto'}} className="image main"><img src={aislynnabout} alt="" /></span>
           
           <p >Aislynn has studied Urasenke Chanoyu on the east coast for eight years. It was in New York City that she began connecting the fundamental principles of mindfulness found in tea ceremony to the fast-paced hustle and bustle of startups, offices, and even private parties. Since moving to Denver this year, she founded Fudō-An Tea and Wellness Services, where she brings tea ceremony workshops, lectures, and matcha education to nearly any venue or event. </p>
         
  </div>
        
         {close}
        </article>

        <article id="contact" className={`${this.props.article === 'contact' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">Contact</h2>
          <div>
            
          <HubspotForm
          portalId= "7874372"
          formId= "f9c0179e-0662-4cba-828b-5633de8e3e8c"
   onSubmit={() => console.log('Submit!')}
   onReady={(form) => console.log('Form ready!')}
   loading={<div>Loading...</div>}
   />
        </div>

          <ul className="icons">
            <li><a href="https://www.facebook.com/fudoantea" className="icon fa-facebook"><span className="label">Facebook</span></a></li>
            <li><a href="https://www.instagram.com/fudoantea" className="icon fa-instagram"><span className="label">Instagram</span></a></li>
            <li><a href="https://www.linkedin.com/company/fudoan" className="icon fa-linkedin"><span className="label">GitHub</span></a></li>
          </ul>
          {close}
        </article>

      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
    onOpenArticle: PropTypes.func,
    timeout: PropTypes.bool

}

export default Main